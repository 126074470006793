import { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchBar from 'components/SearchBar';
import closeIcon from 'assets/icons/close.svg';
import style from './index.module.scss';
import languageIcon from 'assets/icons/language.svg';
import activeLanguageIcon from 'assets/icons/green-language.svg';
import { ReactComponent as LogoArabicIcon } from 'assets/icons/logo_ar.svg';
import { ReactComponent as LogoEnglishIcon } from 'assets/icons/logo_en.svg';
import RuleBookItem from 'components/RuleBookItem';

const MobileMenu = () => {
  const { t, i18n } = useTranslation('header');
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const ChangeLanguage = (lang, event) => {
    i18n.changeLanguage(lang);
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <div className={style.mobileMenu} onClick={toggleMenu}>
      <div className={style.hamburgerMenu}>
        <div className={style.hamburger}></div>
      </div>
      <nav className={`${style.sideMenu} ${menuOpen ? style.active : ''}`}>
        <ul>
          <div className={style.closeIcon} onClick={toggleMenu}>
            <img src={closeIcon} />
          </div>
          <div className={style.convertLanguage}>
            <button className={`${style.languageBtn}`} onClick={() => ChangeLanguage('ar')}>
              <div>
                <img src={i18n.language == 'ar' ? activeLanguageIcon : languageIcon} />
                <span style={{ color: i18n.language == 'ar' && '#8DCA74' }}>عربي</span>
              </div>
            </button>
            <button className={style.languageBtn} onClick={() => ChangeLanguage('en')}>
              <div>
                <span style={{ color: i18n.language == 'en' && '#8DCA74' }}>En</span>
                <img src={i18n.language == 'en' ? activeLanguageIcon : languageIcon} />
              </div>
            </button>
          </div>
          <div className={style.sideMenuRulebook}>
            <h6>{t('rulebook-contents')}</h6>
            <RuleBookItem parentId={'root'} />
          </div>
        </ul>
      </nav>
    </div>
  );
};

const TopHeader = () => {
  const { t, i18n } = useTranslation('header');
  const history = useHistory();
  const mdScreen = useMedia(`(max-width: 768px)`);

  const redirectToPage = (path) => {
    history.push(path);
  };
  const ChangeLanguage = () => {
    i18n.changeLanguage(i18n.language == 'ar' ? 'en' : 'ar');
  };

  return (
    <div className={style.siteHeader}>
      <div className={style.logoWrapper} onClick={() => redirectToPage('/')}>
        {i18n.language === 'ar' ? <LogoArabicIcon /> : <LogoEnglishIcon />}
      </div>
      <div className={style.searchWrapper}>
        <SearchBar />
      </div>
      <div className={style.mobileMenuWrapper}>{mdScreen && <MobileMenu />}</div>
      {!mdScreen && (
        <div>
          <button className={style.languageBtn} onClick={ChangeLanguage}>
            <div>
              <img src={languageIcon} />
              <span>{i18n.language == 'ar' ? 'En' : 'عربي'}</span>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default TopHeader;
